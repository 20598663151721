<template lang="pug">
  header.header
    .left
      h1.brand.inline
        router-link(to="/user/account") {{ $t('user.center') }}

    .right
      ul.inline
        li.inline.p-l-10.p-r-10
          router-link.inline(to="/console")
            Icon.icon.fs-17.m-r-5(type="md-apps")
            span {{ $t('CONSOLE') }}
        li.inline.p-l-10.p-r-10
          Dropdown(trigger="click" @on-click="language($event)")
            .pointer
              Icon.icon.fs-17.m-r-5(type="md-globe")
              span {{ locale_name }}
              Icon(type="ios-arrow-down")
            DropdownMenu(slot="list")
              DropdownItem(:name="item.name" v-for="(item, index) in locales" :key="index") {{ item.label }}
        //- li.inline
        //-   Tooltip(content="系统通知" placement="right")
        //-     Badge(dot)
        //-       Icon.icon(type="md-notifications")

      Dropdown.m-l-10(trigger="click" placement="bottom-end" @on-click="dropdownClick")
        Avatar.pointer(icon="ios-person" :src="user.avatar ? user.avatar : require('@/assets/avatar.jpg')")
        DropdownMenu(slot="list")
          DropdownItem(name="signout" data-divided)
            span {{ $t('user.signout') }}
            Icon.icon.fs-18.m-l-5(type="md-power")
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'UserHeader',
  computed: {
    ...mapState({
      user: state => state.account,
    }),

    locales() {
      const locales = Object.keys(this.$i18n.messages);
      const items = locales.filter(locale => this.$i18n.messages[locale][locale]);
      return items.map(locale => {
        return {
          name: locale,
          label: this.$i18n.messages[locale][this.locale]
        }
      });
    },
    locale() {
      return this.$i18n.locale;
    },
    locale_name() {
      const item = this.locales.find(locale => locale.name === this.locale);
      return item.label;
    }
  },
  data () {
    return {}
  },
  methods: {
    language(lan) {
      sessionStorage.setItem('language', lan);
      this.$i18n.locale = lan;
    },
    dropdownClick(name) {
      switch (name) {
        case 'signout':
          if (this.$socket && this.$socket.connected) this.$socket.disconnect();
          this.$store.dispatch('system/signout');
          this.$store.dispatch('account/signout');
          this.$router.replace({ path: '/sign' });
        break;
      }
    }
  },
  created() {
  }
}
</script>