<template lang="pug">
  #app.user-center.fixed-header(:class="{ 'menu-behind': true }")
    .container
      UserHeader
      main.wrapper
        .content
          Row(:gutter="30")
            iCol(span="6")
              .sticky(:style="{ top: '120px' }")
                Card.bg-transparent.m-b-25.clearfix(:bordered="false" :padding="0" dis-hover)
                  div.left(style="margin-right: 16px;")
                    //- vcUploadWithCrop(:data="user.avatar" :options="options" @success="onSuccess")
                  div.p-t-15.p-b-15
                    h1.h3.fs-20.fw-600.lh-125 {{ user.display_name }}
                    span {{ $t('your.personal.account') }}

                Card.bg-transparent(:padding="0" :bordered="false" dis-hover)
                  .user-aside
                    ul
                      li(v-for="(item, index) in menus" :key="index")
                        router-link(:to="item.to")
                          Icon(:type="item.icon" size="18")
                          span.m-l-5 {{ $t(item.title) }}

                    Divider &amp;

            iCol(span="18")
              router-view
</template>

<script>
import UserHeader from '@/views/ui/UserHeader'
//- import vcUploadWithCrop from '@/components/vcUploadWithCrop'

export default {
  name: 'UserLayout',
  components: {
    UserHeader,
    //- vcUploadWithCrop
  },
  data() {
    return {
      loading: false,
      
      options: {
        app: 'admin',
        module: 'user',
        key: 'avatar',
        size: [ 80, 80 ],
        style: {
          borderRadius: '50%'
        }
      },

      user: {
        // display_name: 'tmp',
        // avatar: {
        //   src: require('@/assets/avatar.jpg')
        // }
      },

      menus: [
        { icon: 'logo-buffer', title: 'profile', to: '/user/profile' },
        { icon: 'md-person', title: 'account', to: '/user/account' },
        { icon: 'md-cash', title: 'assets and billing', to: '/user/asset' },
        { icon: 'md-checkmark-circle-outline', title: 'authentication', to: '/user/authentication' },
        { icon: 'md-color-palette', title: 'appearance', to: '/user/appearance' },
        { icon: 'md-notifications', title: 'notifications', to: '/user/notification' },
        { icon: 'md-lock', title: 'security', to: '/user/security' },
      ]
    }
  },
  computed: {
    route_path() {
      return this.$route.path;
    }
  },
  methods: {
    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      console.log(key, res)
      // this.detail[key] = res;
    },

    async get_user_detail() {
      try {
        this.loading = true;
        const res = await this.$http.get('user/profile');

        this.user = res;

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$message.error(err);
      }
    }
  },
  created() {},
  mounted() {
    this.get_user_detail();
  },
  beforeCreate() {},
  beforeDestroy() {}
}
</script>

<style lang="less">
.user-aside {
  a, a:hover { text-decoration: none;}
  ul {
    li {
      padding-left: 8px;
      a {
        color: #515a6e;
        font-size: 14px;
        display: block; padding: 7px 8px; border-radius: 4px;
        &:hover {
          background-color: rgb(245, 245, 245);
        }
        &.router-link-active {
          color: #2d8cf0;
          background-color: #f0faff;
          position: relative;
          &::before {
            content: ''; display: block;
            width: 4px;
            position: absolute; left: -8px; top: 5px; bottom: 5px;
            background: #2d8cf0; border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>